<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-table
        class="position-relative"
        :items="packageSubscriptions"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        style="min-height: 150px"
      >

        <!-- Column: Description -->
        <template #cell(title)="data">
          {{ data.item.title }}
          <br>
          <small class="text-muted">{{ data.item.subTitle }}</small>
        </template>

        <!-- Column: Tenure -->
        <!-- TODO: change it to resolveTenure -->
        <template #cell(tenure)="data">
          {{ data.item.tenure === 'Y' ? 'Yearly' : 'Monthly' }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import usePackageSubscriptionsList from './usePackageSubscriptionsList'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    packageSubscriptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      tableColumns,
    } = usePackageSubscriptionsList()

    return {
      tableColumns,

      resolveStatus,
      resolveStatusVariant,
    }
  },
}
</script>
