<template>

  <div>

    <!-- Filters -->
    <packages-list-filters
      :name-filter.sync="nameFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="$ability.can('C', 'Packages & Subscriptions')"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'general-settings-packages-create' }"
              >
                <span class="text-nowrap">Add Package</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPackageListTable"
        class="position-relative"
        :items="fetchPackages"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="$ability.can('E', 'Packages & Subscriptions')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('E', 'Packages & Subscriptions')"
              :to="{ name: 'general-settings-packages-edit', params: { id: data.item.packageId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>

          <span
            v-else
            class="text-muted"
          >
            N/A
          </span>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          {{ data.item.name }}
          <br>
          <small class="text-muted">{{ data.item.description }}</small>
        </template>

        <!-- Column: With Hardware -->
        <template #cell(withHardware)="data">
          <b-avatar
            :variant="data.item.withHardware ? 'light-success' : 'light-danger'"
            size="16"
          >
            <feather-icon
              :icon="data.item.withHardware ? 'CheckCircleIcon' : 'XCircleIcon'"
              size="16"
            />
          </b-avatar>
        </template>

        <!-- Column: With Hardware -->
        <template #cell(forHalaCashier)="data">
          <b-badge
            pill
            :variant="data.item.forHalaCashier ? 'light-secondary' : 'light-info'"
            class="text-capitalize"
          >
            {{ data.item.forHalaCashier ? 'Main' : 'Lite' }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <package-subscriptions-list :package-subscriptions="data.item.subscriptions" />
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BAvatar,
  BBadge, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import packageStoreModule from '../packageStoreModule'
import usePackagesList from './usePackagesList'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'
import { statusOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import PackagesListFilters from './PackagesListFilters.vue'
import PackageSubscriptionsList from './package-subscriptions-list/PackageSubscriptionsList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    BAvatar,

    vSelect,

    PackagesListFilters,
    PackageSubscriptionsList,
  },
  directives: {
    Ripple,
  },
  setup() {
    const PACKAGE_APP_STORE_MODULE_NAME = 'app-package'

    // Register module
    if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.registerModule(PACKAGE_APP_STORE_MODULE_NAME, packageStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPackages,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPackageListTable,
      refetchData,

      nameFilter,
      statusFilter,
    } = usePackagesList()

    return {
      fetchPackages,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPackageListTable,
      refetchData,

      //   Options
      statusOptions,

      resolveStatus,
      resolveStatusVariant,

      //   Filters
      nameFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
