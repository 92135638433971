export default function usePackageSubscriptionsList() {
  // Table Handlers
  const tableColumns = [
    { key: 'title' },
    { key: 'price' },
    { key: 'tenure' },
    { key: 'status' },
  ]

  return {
    tableColumns,
  }
}
