import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function usePrivilegesList() {
  // Use toast
  const toast = useToast()

  const refPackageListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'name', sortable: true },
    { key: 'branchesLimit' },
    { key: 'cashiersPerBranchLimit', label: 'Cashiers Limit Per Branch' },
    { key: 'withHardware', tdClass: 'text-center' },
    { key: 'forHalaCashier', label: 'Is For Main Or Lite?' },
    { key: 'status', sortable: true },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const nameFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPackageListTable.value ? refPackageListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refPackageListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, nameFilter, statusFilter], () => {
    refetchData()
  })

  const fetchPackages = (ctx, callback) => {
    store
      .dispatch('app-package/fetchPackages', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        description: nameFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Packages list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPackages,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPackageListTable,
    refetchData,

    // Extra Filters
    nameFilter,
    statusFilter,
  }
}
